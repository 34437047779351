<template>
  <LayoutTunnel>
    <template slot="left-side">
      <div
        class="flex flex-col flex-1 items-start self-center justify-around px-16 !py-10 lg:px-8 max-w-xl xl:w-144 lg:w-full"
      >
        <div></div>
        <div class="flex flex-col space-y-6 w-full">
          <p
            id="text-find-offre"
            style="min-height: 190px"
            class="text-promy-purple-900"
          ></p>
          <div>
            <validation-provider
              class="flex flex-col"
              name="form.civilite"
              rules="required"
              v-slot="{ errors }"
            >
              <div class="space-x-4 flex">
                <RadioInput
                  :id="_uid + 'madame'"
                  label="Madame"
                  :value="form.civilite"
                  @change="change($event)"
                  classes="space-x-2"
                >
                  Madame
                </RadioInput>
                <RadioInput
                  :id="_uid + 'monsieur'"
                  label="Monsieur"
                  :value="form.civilite"
                  @change="change($event)"
                  classes="space-x-2"
                >
                  Monsieur
                </RadioInput>
              </div>
              <div class="w-full">
                <span class="text-red-600 mt-2" v-if="errors[0]">
                  {{ errors[0] }}
                </span>
              </div>
            </validation-provider>
          </div>
          <div>
            <TextInput
              label="Prénom"
              placeholder="Prénom"
              rules="required"
              :value="form.prenom"
              @input="
                (val) => {
                  form.prenom = val
                }
              "
            />
          </div>
          <div>
            <TextInput
              label="Nom"
              placeholder="Nom"
              rules="required"
              :value="form.nom"
              @input="
                (val) => {
                  form.nom = val
                }
              "
            />
          </div>
          <div>
            <TextInput
              label="Email"
              placeholder="Email"
              rules="required|email"
              :value="form.email"
              @input="
                (val) => {
                  form.email = val
                }
              "
            />
          </div>
          <div style="margin-bottom: 25px">
            <PrefixedTextInput
              label="Téléphone"
              placeholder="Tel"
              rules="required|phoneNumber"
              :hasPrefix="true"
              :prefixOptions="['+33']"
              :value="form.tel"
              v-model="form.tel"
              @input="
                ({ prefix, value }) => {
                  form.prefix = prefix
                  form.tel = value
                }
              "
            />
          </div>
        </div>
        <portal-target
          class="sm:w-full sm:py-2 sm:mt-2"
          name="next-prev-step"
        />
      </div>
    </template>
    <template slot="right-side">
      <div
        class="flex flex-1 w-full justify-center items-center bg-promy-blue-100 bg-opacity-25"
      >
        <img src="/images/findOffre-loupe.svg" class="w-3/4" alt="" />
      </div>
    </template>
  </LayoutTunnel>
</template>
<script>
import Map from '../Map'
import PrefixedTextInput from '../../../components/inputs/PrefixedTextInput'

export default {
  components: { PrefixedTextInput, Map },
  props: {
    form: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isActive(is_active) {
      if (is_active) {
        this.$gsap.to('#text-find-offre', {
          text: `Nous avons trouvé <strong>47 professionnels</strong> qui cherchent à acquérir un bien comme le vôtre. Nous pouvons <strong>gratuitement</strong> estimer le potentiel de votre bien, monter votre dossier et vous présenter <strong>les meilleures offres d’achat.</strong><br/><br/>Pour cela, il vous suffit de renseigner vos coordonnées et notre équipe vous recontactera au plus vite !`,
          ease: 'power1.in',
          duration: 4,
          repeat: 0,
          yoyo: true,
          repeatDelay: 0.4,
        })
      }
    },
  },
  methods: {
    change(value) {
      this.form.civilite = value
    },
  },
}
</script>
